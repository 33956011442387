"use client";

import { cn } from "@/lib/utils.ts";
import type { ToastMessage, ToastType } from "@/types/Notifications";
import { XMarkIcon } from "@heroicons/react/20/solid";
import * as RadixToast from "@radix-ui/react-toast";
import { AnimatePresence, motion } from "framer-motion";
import {
	type ElementRef,
	type ReactNode,
	createContext,
	forwardRef,
	useCallback,
	useContext,
	useState,
} from "react";
import ReactMarkdown from "react-markdown";

const ZipToastContext = createContext<{
	showToast: ({ type, message, duration, useMarkdown }: ToastMessage) => void;
}>({
	showToast: () => {
		throw new Error(
			"You can't call showToast() outside of a <ToastProvider> – add it to your tree.",
		);
	},
});

export function useToast() {
	return useContext(ZipToastContext);
}

export function ToastProvider({ children }: { children: ReactNode }) {
	const [messages, setMessages] = useState<ToastMessage[]>([]);

	const showToast = useCallback(
		({
			message,
			className = "",
			type = "success",
			duration = 5000,
			useMarkdown = false,
		}: ToastMessage) => {
			setMessages((toasts) => [
				...toasts,
				{
					id: window.crypto.randomUUID(),
					message,
					type,
					duration,
					className,
					useMarkdown,
				},
			]);
		},
		[],
	);

	return (
		<RadixToast.Provider>
			<ZipToastContext.Provider value={{ showToast }}>
				{children}
			</ZipToastContext.Provider>
			<AnimatePresence mode="popLayout">
				{messages.map((toast) => (
					<Toast
						key={toast.id}
						message={toast.message}
						useMarkdown={toast.useMarkdown}
						type={toast.type as ToastType}
						duration={toast.duration}
						className={toast.className}
						onClose={() =>
							setMessages((toasts) => toasts.filter((t) => t.id !== toast.id))
						}
					/>
				))}
			</AnimatePresence>
			<RadixToast.Viewport className="max-sm:top-20 fixed top-4 right-4 flex w-80 flex-col-reverse gap-3 z-1000" />
		</RadixToast.Provider>
	);
}

const Toast = forwardRef<
	ElementRef<typeof RadixToast.Root>,
	{
		onClose: () => void;
	} & ToastMessage
>(function Toast(
	{
		onClose,
		message,
		type,
		className = "",
		duration = 2500,
		useMarkdown = false,
	},
	forwardedRef,
) {
	const width = 320;
	const margin = 16;

	return (
		<RadixToast.Root
			ref={forwardedRef}
			asChild
			forceMount
			onOpenChange={onClose}
			duration={duration}
		>
			<motion.li
				layout
				initial={{ x: width + margin }}
				animate={{ x: 0 }}
				exit={{
					opacity: 0,
					zIndex: -1,
					transition: {
						opacity: {
							duration: 0.2,
						},
					},
				}}
				transition={{
					type: "spring",
					mass: 1,
					damping: 30,
					stiffness: 200,
				}}
				style={{ width, WebkitTapHighlightColor: "transparent" }}
			>
				<div
					className={cn(
						// `overflow-hidden whitespace-nowrap`,
						"flex items-center justify-between rounded-lg border text-sm shadow-sm backdrop-blur",
						type === "success" &&
							"text-brand-legible border-gray-600 bg-brand-primary",
						type === "error" && "text-brand-legible border-red-600 bg-red-600",
						type === "info" &&
							"text-brand-primary border-brand-primary bg-white",
					)}
				>
					<RadixToast.Description
						className={cn(
							//`truncate`,
							"p-4",
							className,
						)}
					>
						{useMarkdown ? (
							<ReactMarkdown className={"break-words"}>{message}</ReactMarkdown>
						) : (
							message
						)}
					</RadixToast.Description>
					<RadixToast.Close
						className={cn(
							"border-l border-gray-600/50 p-4 transition ",
							"text-brand-legible-light hover:bg-legible-light hover:text-brand-legible active:text-white",
							type === "error" &&
								"text-red-200 hover:bg-red-600 hover:text-white",
							type === "info" &&
								"text-brand-primary/50 hover:bg-brand-primary hover:text-white",
						)}
					>
						<XMarkIcon className="h-5 w-5" />
					</RadixToast.Close>
				</div>
			</motion.li>
		</RadixToast.Root>
	);
});
